const dataTableColumns = {
  dataTableColumns: {
    restaurants: {
      image: 'Imagen',
      name: 'Nombre',
      description: 'Descripción',
      actions: 'Acciones'
    },
    products: {
      image: 'Imagen',
      name: 'Nombre',
      isVisible: 'Estado',
      isRecommended: 'Destacado',
      actions: 'Acciones'
    },
    branches: {
      image: 'Imagen',
      branch: 'Sucursal',
      isVisible: 'Estado',
      actions: 'Acciones'
    },
    allergens: {
      image: 'Imagen',
      name: 'Nombre',
      actions: 'Acciones'
    },
    tags: {
      image: 'Imagen',
      name: 'Nombre',
      actions: 'Acciones'
    },
    paymentMethods: {
      id: "Id",
      method: 'Medio de pago',
      actions: 'Acciones'
    },
    deliveryMethods: {
      id: "Id",
      method: 'Método de envío',
      actions: 'Acciones'
    },
    users: {
      image: 'Imagen',
      name: 'Nombre',
      email: 'Mail',
      restaurant: 'Restaurant',
      role: 'Rol',
      sentEmail: 'Mail enviado',
      actions: 'Acciones'
    },
    currencies: {
      name: 'Nombre',
      isoCode: 'Código ISO',
      symbol: 'Símbolo',
      actions: 'Acciones'
    },
    countries: {
      name: 'Nombre',
      actions: 'Acciones'
    },
    billing: {
      branch: 'Sucursal',
      plan: 'Plan',
      country: 'País',
      paidStatus: 'Estado de pago'
    },
    menus: {
      name: 'Nombre',
      branch: 'Sucursal',
      categoriesCount: 'Categories en el Menú',
      state: 'Estado',
      actions: 'Acciones'
    },
    categories: {
      image: 'Imagen',
      category: 'Categoría',
      actions: 'Acciones'
    },
    toppings: {
      toppings: 'Toppings',
      isVisible: 'Estado',
      actions: 'Acciones'
    },
    accompaniments: {
      accompaniments: 'Acompañamientos',
      isVisible: 'Estado',
      actions: 'Acciones'
    },
    variants: {
      variants: 'Variantes',
      isVisible: 'Estado',
      actions: 'Acciones'
    },
    models: {
      dishName: 'Nombre del Plato',
      model: 'Model',
      actions: 'Acciones'
    },
    paymentTransactions: {
      name: 'Periodo',
      amount: 'Monto',
      state: 'Estado',
      actions: 'Acciones',
    },
    plans: {
      image: 'Imagen',
      name: 'Nombre',
      recommendedDishes: 'Platos Destacados',
      maximumMenus: 'Cantidad de Menus',
      cartearWaiters: 'Cartear Mozos',
      notifications: 'Notificaciones',
      actions: 'Acciones'
    },
    planPromotions: {
      period: 'Periodo',
      discount: 'Descuento',
      actions: 'Acciones'
    },
    mobileVersions: {
      image: 'Imagen',
      version: 'Version',
      minimunVersion: 'Soporte Mínimo',
      so: 'Sistema Operativo',
      actions: 'Acciones'
    },
    suggestions: {
      uid: 'UID',
      email: 'Email',
      comment: 'Comentario',
      date: 'Fecha',
      actions: 'Acciones'
    },
    notifications: {
      date: 'Fecha',
      topic: 'Topic',
      hour: 'Hour',
      title: 'Título',
      message: 'Mensaje',
      actions: 'Acciones'
    }
  },
}

export default dataTableColumns;
