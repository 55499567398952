const dataTableColumns = {
  dataTableColumns: {
    restaurants: {
      image: 'Image',
      name: 'Name',
      description: 'Description',
      actions: 'Actions'
    },
    products: {
      image: 'Image',
      name: 'Name',
      isVisible: 'Visibility',
      isRecommended: 'Featured',
      actions: 'Actions'
    },
    branches: {
      image: 'Image',
      branch: 'Branch',
      isVisible: 'Visibility',
      actions: 'Actions'
    },
    allergens: {
      image: 'Image',
      name: 'Name',
      actions: 'Actions'
    },
    tags: {
      image: 'Image',
      name: 'Name',
      actions: 'Actions'
    },
    paymentMethods: {
      id: "Id",
      method: 'Payment Method',
      actions: 'Actions'
    },
    deliveryMethods: {
      id: "Id",
      method: 'Delivery Method',
      actions: 'Actions'
    },
    users: {
      image: 'Image',
      name: 'Name',
      email: 'Email',
      restaurant: 'Restaurant',
      role: 'Role',
      sentEmail: 'Email Sent',
      actions: 'Actions'
    },
    currencies: {
      name: 'Name',
      isoCode: 'ISO Code',
      symbol: 'Symbol',
      actions: 'Actions'
    },
    countries: {
      name: 'Name',
      actions: 'Actions'
    },
    billing: {
      branch: 'Branch',
      plan: 'Plan',
      country: 'Country',
      paidStatus: 'Payment Status'
    },
    menus: {
      name: 'Name',
      branch: 'Branch',
      categoriesCount: 'Categories in Menu',
      state: 'State',
      actions: 'Actions'
    },
    categories: {
      image: 'Image',
      category: 'Category',
      actions: 'Actions'
    },
    toppings: {
      toppings: 'Toppings',
      isVisible: 'Visibility',
      actions: 'Actions'
    },
    accompaniments: {
      accompaniments: 'Accompaniments',
      isVisible: 'Visibility',
      actions: 'Actions'
    },
    variants: {
      variants: 'Variants',
      isVisible: 'Visibility',
      actions: 'Actions'
    },
    models: {
      dishName: 'Dish Name',
      model: 'Model',
      actions: 'Actions'
    },
    paymentTransactions: {
      name: 'Period',
      amount: 'Amount',
      state: 'State',
      actions: 'Actions',
    },
    plans: {
      image: 'Image',
      name: 'Name',
      recommendedDishes: 'Featured Dishes',
      maximumMenus: 'Menu Quantity',
      cartearWaiters: 'Waiter Ordering',
      notifications: 'Notifications',
      actions: 'Actions'
    },
    planPromotions: {
      period: 'Period',
      discount: 'Discount',
      actions: 'Actions'
    },
    mobileVersions: {
      image: 'Image',
      version: 'Version',
      minimunVersion: 'Minimum Support',
      so: 'Operating System',
      actions: 'Actions'
    },
    suggestions: {
      uid: 'UID',
      email: 'Email',
      comment: 'Comment',
      date: 'Date',
      actions: 'Actions'
    },
    notifications: {
      date: 'Date',
      topic: 'Topic',
      hour: 'Hour',
      title: 'Title',
      message: 'Message',
      actions: 'Actions'
    }
  }
 }

export default dataTableColumns;
