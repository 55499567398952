const entities = {
  entities: {
    restaurants: {
      title: "Restaurantes",
      searchInput: "Restaurante",
      singular: "Restaurante",
      actions: {
        edit: "Editar",
        models3D: "Modelos 3D",
        loginAs: "Login As",
        branches: "Sucursales",
        delete: "Eliminar",
        show: "Ver",
      },
    },
    branches: {
      title: "Sucursales",
      searchInput: "Sucursal",
      singular: "Sucursal",
      edit: "Editar Sucursal",
      actions: {
        edit: "Editar",
        loginAs: "Login As",
        delete: "Eliminar",
        translations: "Traducciones",
      },
      attributes: {
        subtitle: "Subtítulo",
        description: "Descripción",
      },
    },
    products: {
      title: "Productos",
      searchInput: "Producto",
      singular: "Producto",
      attributes: {
        title: "Nombre",
        description: "Descripción",
        short_description: "Resumen",
      },
      actions: {
        edit: "Editar",
        share: "Link para compartir",
        translations: "Traducciones",
        delete: "Eliminar",
        clone: "Clonar",
        show: "Ver",
      },
    },
    allergens: {
      title: "Alérgenos",
      searchInput: "Alérgeno",
      singular: "Alérgeno",
      actions: {
        edit: "Editar",
        translations: "Traducciones",
        delete: "Eliminar",
        show: "Ver",
      },
    },
    tags: {
      title: "Etiquetas",
      searchInput: "Etiqueta",
      singular: "Etiqueta",
      actions: {
        edit: "Editar",
        translations: "Traducciones",
        delete: "Eliminar",
        show: "Ver",
      },
    },
    currencies: {
      title: "Divisas",
      searchInput: "Divisa",
      singular: "Divisa",
      actions: {
        edit: "Editar",
        delete: "Eliminar",
      },
    },
    countries: {
      title: "Países",
      searchInput: "País",
      singular: "País",
      actions: {
        edit: "Editar",
        delete: "Eliminar",
      },
    },
    payment_methods: {
      title: "Medios de pago",
      searchInput: "Medio de pago",
      singular: "",
      actions: {
        translations: "Traducciones",
      },
    },
    delivery_methods: {
      title: "Métodos de envío",
      searchInput: "Método de envío",
      singular: "",
      actions: {
        translations: "Traducciones",
      },
    },
    users: {
      title: "Usuario",
      searchInput: "Usuario",
      singular: "Usuario",
      actions: {
        edit: "Editar",
        sendEmail: "Enviar Email",
        delete: "Eliminar",
      },
    },
    menus: {
      title: "Menu",
      searchInput: "Menu",
      singular: "Menu",
      attributes: {
        name: "Nombre",
        description: "Descripción",
      },
      optionalButton: {
        name: "CLONAR MENU",
        action: "clone",
      },
      actions: {
        edit: "Editar",
        assign: "Asignar Sucursales",
        translations: "Traducciones",
        delete: "Eliminar",
        show: "Ver",
      },
    },
    categories: {
      title: "Categorias",
      searchInput: "Categoria",
      singular: "Categoria",
      attributes: {
        name: "Nombre",
        description: "Descripción",
      },
      actions: {
        edit: "Editar",
        translations: "Traducciones",
        delete: "Eliminar",
        show: "Ver",
      },
    },
    toppings: {
      title: "Toppings",
      searchInput: "Topping",
      singular: "Topping",
      actions: {
        edit: "Editar",
        translations: "Traducciones",
        delete: "Eliminar",
        show: "Ver",
      },
    },
    accompaniments: {
      title: "Acompañamientos",
      searchInput: "Acompañamiento",
      singular: "Acompañamiento",
      actions: {
        edit: "Editar",
        translations: "Traducciones",
        delete: "Eliminar",
        show: "Ver",
      },
    },
    variants: {
      title: "Variantes",
      searchInput: "Variante",
      singular: "Variante",
      actions: {
        edit: "Editar",
        translations: "Traducciones",
        delete: "Eliminar",
        show: "Ver",
      },
    },
    models: {
      title: "Platos",
      searchInput: "plato",
      filterButton: {
        name: "Filtro",
        action: "filter",
      },
      actions: {
        edit: "Editar",
      },
    },
    payment_transactions: {
      title: "Mis Pagos",
      searchInput: "",
      singular: "",
    },
    plans: {
      title: "Planes",
      searchInput: "Plan",
      singular: "Plan",
      actions: {
        edit: "Editar",
        promotions: "promociones",
        delete: "Eliminar",
      },
    },
    plan_promotions: {
      title: "Promociones",
      searchInput: "Promoción",
      singular: "Promoción",
      actions: {
        edit: "Editar",
        delete: "Eliminar",
      },
    },
    mobile_versions: {
      title: "Versiones",
      searchInput: "Versión",
      singular: "Versión",
      actions: {
        edit: "Editar",
        translations: "Traducciones",
        delete: "Eliminar",
      },
    },
    suggestions: {
      title: "Sugerencias",
      searchInput: "UID o Email",
      singular: "",
      actions: {
        show: "Ver sugerencia",
      },
    },
    notifications: {
      title: "Notificaciones",
      searchInput: "",
      singular: "",
      actions: {
        show: "Ver detalle",
      },
    },
    billing: {
      title: "Sucursales",
      searchInput: "Sucursal",
      singular: "",
      actions: {
        payed: "Pago",
        unpayed: "Impago",
      },
    },
    schedules: {
      title: "Horarios",
    },
  },
};

export default entities;
