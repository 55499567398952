export const modalContent = {
  restaurants: {
    delete: {
      title: (name) => `¿Está seguro de eliminar el restaurant ${name}?`,
      text: "Una vez eliminado, se eliminarán también todas sus sucursales.",
      confirmText: (name) => `El restaurant ${name} ha sido eliminado.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar el restaurant ${name}.`,
    },
  },
  branches: {
    delete: {
      title: (name) => `¿Está seguro de eliminar la sucursal ${name}?`,
      text: "Una vez eliminada, se eliminarán también todas sus datos.",
      confirmText: (name) => `La sucursal ${name} ha sido eliminado.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar la sucursal ${name}.`,
    },
  },
  allergens: {
    delete: {
      title: (name) => `¿Está seguro de eliminar el alérgeno ${name}?`,
      text: null,
      confirmText: (name) => `El alérgeno ${name} ha sido eliminado.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar el alérgeno ${name}.`,
    },
  },
  tags: {
    delete: {
      title: (name) => `¿Está seguro de eliminar la etiqueta ${name}?`,
      text: null,
      confirmText: (name) => `La etiqueta ${name} ha sido eliminado.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar la etiqueta ${name}.`,
    },
  },
  users: {
    delete: {
      title: (name) => `¿Está seguro de eliminar el usuario ${name}?`,
      text: null,
      confirmText: (name) => `El usuario ${name} ha sido eliminado.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar el usuario ${name}.`,
    },
  },
  currencies: {
    delete: {
      title: (name) => `¿Está seguro de eliminar la divisa ${name}?`,
      text: null,
      confirmText: (name) => `La divisa ${name} ha sido eliminada.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar la divisa ${name}.`,
    },
  },
  countries: {
    delete: {
      title: (name) => `¿Está seguro de eliminar el país ${name}?`,
      text: null,
      confirmText: (name) => `El país ${name} ha sido eliminado.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar el país ${name}.`,
    },
  },
  categories: {
    delete: {
      title: (name) => `¿Está seguro de eliminar la categoría ${name}?`,
      text: "Se eliminará también de todas las sucursales",
      confirmText: (name) => `La categoría ${name} ha sido eliminada.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar la categoría ${name}.`,
    },
  },
  menus: {
    delete: {
      title: (name) => `¿Está seguro de eliminar el menú ${name}?`,
      text: null,
      confirmText: (name) => `El menú ${name} ha sido eliminado.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar el menú ${name}.`,
    },
  },
  products: {
    delete: {
      title: (name) => `¿Está seguro de eliminar el producto ${name}?`,
      text: "Se eliminará también de todas las sucursales",
      confirmText: (name) => `El producto ${name} ha sido eliminado.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar el producto ${name}.`,
    },
  },
  toppings: {
    delete: {
      title: (name) => `¿Está seguro de eliminar el topping ${name}?`,
      text: "Se eliminará también de todas las sucursales",
      confirmText: (name) => `El topping ${name} ha sido eliminado.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar el topping ${name}.`,
    },
  },
  accompaniments: {
    delete: {
      title: (name) => `¿Está seguro de eliminar el acompañamiento ${name}?`,
      text: "Se eliminará también de todas las sucursales",
      confirmText: (name) => `El acompañamiento ${name} ha sido eliminado.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar el acompañamiento ${name}.`,
    },
  },
  variants: {
    delete: {
      title: (name) => `¿Está seguro de eliminar la variante ${name}?`,
      text: "Se eliminará también de todas las sucursales",
      confirmText: (name) => `La variante ${name} ha sido eliminada.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar la variante ${name}.`,
    },
  },
  plans: {
    delete: {
      title: (name) => `¿Está seguro de eliminar el plan ${name}?`,
      text: null,
      confirmText: (name) => `El plan ${name} ha sido eliminado.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar el plan ${name}.`,
    },
  },
  plan_promotions: {
    delete: {
      title: () => "¿Está seguro de eliminar esta promoción?",
      text: null,
      confirmText: () => "La promoción ha sido eliminado.",
      errorText: () =>
        "Ha ocurrido un error al intentar eliminar la promoción.",
    },
  },
  payment_transactions: {
    cancel: {
      title: `¿Está seguro de cancelar esta transacción?`,
      text: null,
      confirmText: `La transacción ha sido cancelada.`,
      errorText: `Ha ocurrido un error al intentar cancelar la transacción.`,
    },
  },
  tables: {
    delete: {
      title: (name) => `¿Está seguro de eliminar la mesa ${name}?`,
      text: "Si confirma, se borrará la mesa y todos los pedidos que alguna vez tuvo la mesa. Esto impactará en las estadísticas",
      confirmText: (name) => `La mesa ${name} ha sido eliminada.`,
      errorText: (name) =>
        `Ha ocurrido un error al intentar eliminar la mesa ${name}.`,
    },
  },
};
