//Dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import Datatable from "../components/Datatable";

//Assets
import {
  setAccompaniments,
  setHasMore,
  setNextPage,
  setIsLoading,
  clearAccompaniments,
} from "../actions";

//Utils
import { getAll } from "../utils/services";
import { CheckHasMore } from "../utils/CheckHasMore";

const ENTITY = "accompaniments";

const Accompaniments = (props) => {
  const {
    setHasMore,
    setNextPage,
    setAccompaniments,
    setIsLoading,
    clearAccompaniments,
    loggedUser,
  } = props;

  const { id } = useParams();
  const ACCOMPANIMENTS_URL = getAll.accompaniments(
    id ? id : loggedUser.restaurant_id,
    loggedUser.role == "branch_user" ? loggedUser.branch_id : null
  );

  useEffect(() => {
    clearAccompaniments();
    setIsLoading(true);
    axios
      .get(ACCOMPANIMENTS_URL)
      .then((res) => {
        const { accompaniments } = res.data;

        setAccompaniments(accompaniments);

        CheckHasMore(null, null, setHasMore, setNextPage);
      })
      .then(() => setIsLoading(false));
  }, []);

  return <Datatable entity={ENTITY} />;
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setAccompaniments,
  clearAccompaniments,
  setHasMore,
  setNextPage,
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Accompaniments);
