//Dependencies
import React from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

//Assets
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import * as actions from "../actions";

//Utils
import { filter } from "../utils/services";
import { entities } from "../utils/partials.json";
import { DatatableFilterItems } from "../utils/DatatableFilterItems";

const DatatableFilter = (props) => {
  const { entity, setIsLoading, loggedUser } = props;
  const { id } = useParams();

  const handleFilterItems = (query) => {
    setIsLoading(true);

    const FILTER_DATATABLE_URL = filter[entity](
      query,
      ["allergens", "tags"].includes(entity) ? id : id ? id : loggedUser.restaurant_id
    );

    DatatableFilterItems(
      entity,
      FILTER_DATATABLE_URL,
      props,
      query,
      setIsLoading
    );
  };

  const handleKeyPress = (e) => {
    const query = e.target.value;

    if (e.key.toUpperCase() === "ENTER") {
      handleFilterItems(query);
    }
  };

  const handleOnChange = (query) => {
    const clearButton = document.querySelector(".clear-icon");

    if (query === "") {
      clearButton.classList.remove("show");
      handleFilterItems(query);
    } else {
      clearButton.classList.add("show");
    }
  };

  const handleSearch = () => {
    const query = document.querySelector(".filter-input").value;
    handleFilterItems(query);
  };

  const handleClearFilter = () => {
    document.querySelector(".filter-input").value = "";
    document.querySelector(".clear-icon").classList.remove("show");
    handleFilterItems("");
  };

  return (
    <>
      {entities[entity].searchInput ? (
        <>
          <input
            type="text"
            className="filter-input"
            placeholder={`Nombre de ${entities[entity].searchInput}`}
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={(e) => handleOnChange(e.target.value)}
          />
          <div className="clear-icon" onClick={() => handleClearFilter()}>
            <ClearIcon />
          </div>
          <div className="search-icon" onClick={() => handleSearch()}>
            <SearchIcon />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentSection: state.currentSection,
    filteredItems: state.filteredItems,
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(DatatableFilter);
