//Dependencies
import axios from "axios";

//Utils
import { CheckHasMore } from "./CheckHasMore";

export const DatatableFilterItems = (
  entity,
  FILTER_DATATABLE_URL,
  actions,
  query,
  setIsLoading
) => {
  axios
    .get(FILTER_DATATABLE_URL)
    .then((res) => {
      const filteredItems = res.data[entity === "models" ? "products" : entity];

      const {
        meta: {
          links: { next, last },
        },
      } = res.data;

      CheckHasMore(next, last, actions.setHasMore, actions.setNextPage);

      if (!query) {
        switch (entity) {
          case "restaurants":
            actions.clearRestaurants();
            actions.setRestaurants(filteredItems);
            break;
          case "branches":
            actions.clearBranches();
            actions.setBranches(filteredItems);

            break;
          case "products":
            actions.clearProducts();
            actions.setProducts(filteredItems);

            break;
          case "menus":
            actions.clearMenus();
            actions.setMenus(filteredItems);

            break;
          case "allergens":
            actions.clearAllergens();
            actions.setAllergens(filteredItems);

            break;
          case "tags":
            actions.clearTags();
            actions.setTags(filteredItems);

            break;
          case "payment_methods":
            actions.clearPaymentMethods();
            actions.setPaymentMethods(filteredItems);

            break;
          case "delivery_methods":
            actions.clearShippingMethods();
            actions.setShippingMethods(filteredItems);

            break;
          case "users":
            actions.clearUsers();
            actions.setUsers(filteredItems);

            break;
          case "currencies":
            actions.clearCurrencies();
            actions.setCurrencies(filteredItems);

            break;
          case "countries":
            actions.clearCountries();
            actions.setCountries(filteredItems);

            break;
          case "categories":
            actions.clearCategories();
            actions.setCategories(filteredItems);

            break;
          case "toppings":
            actions.clearToppings();
            actions.setToppings(filteredItems);

            break;
          case "accompaniments":
            actions.clearAccompaniments();
            actions.setAccompaniments(filteredItems);

            break;
          case "variants":
            actions.clearVariants();
            actions.setVariants(filteredItems);

            break;
          case "plans":
            actions.clearPlans();
            actions.setPlans(filteredItems);

            break;
          case "suggestions":
            actions.clearSuggestions();
            actions.setSuggestions(filteredItems);

            break;
          case "notifications":
            actions.clearNotifications();
            actions.setNotifications(filteredItems);

            break;
          case "models":
            actions.clearModels();
            actions.setModels(filteredItems);

            break;
          default:
            break;
        }
      } else {
        actions.setFilteredItems(filteredItems);
      }
    })
    .then(() => setIsLoading(false));
};
