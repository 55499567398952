// Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router-dom";

//Components
import ItemsList from "../components/ItemsList";

//Assets
import "../stylesheets/AccompanimentForm.css";
import cartearSelectStyles from "../utils/cartearSelectStyles";
import * as actions from "../actions";

//Utils
import { create, update } from "../utils/services";
import {
  is_obliged_options,
  is_visible_options,
  accompaniment_limit_options,
  set_entity_limit_options,
} from "../utils/selectValues";
import {
  modalAccompanimentSuccessful,
  modalAccompanimentError,
} from "../utils/modals";

const ENTITY = "accompaniments";

const AccompanimentForm = (props) => {
  const {
    id,
    branch_id,
    name,
    translations,
    limit,
    is_visible,
    is_required,
    action,
    updateAccompaniment,
    loggedUser,
    addEntityItem,
    clearEntityItems,
    entityItems,
    items,
    showLoadingOverlay,
  } = props;

  const { t } = useTranslation();
  const RESTAURANT_ID = loggedUser.restaurant_id;
  const BRANCH_ID = loggedUser.branch_id;
  const readOnly = loggedUser.role === "branch_user" && !branch_id && !!id;
  const ACCOMPANIMENT_LIMIT = set_entity_limit_options(
    accompaniment_limit_options,
    1,
    40
  );
  const [isVisibleSelect, setIsVisibleSelect] = useState([]);
  const [isRequiredSelect, setIsRequiredSelect] = useState([]);
  const [limitSelect, setLimitSelect] = useState([]);

  const { register, handleSubmit, setValue } = useForm();
  const history = useHistory();

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    const accompaniment = {
      ...data,
      restaurant_id: RESTAURANT_ID,
      branch_id: BRANCH_ID,
      items_attributes: entityItems.map((i) => ({
        ...i,
        translations_attributes: [
          {
            att: "name",
            description: i.name,
            locale: loggedUser.primary_lang,
          },
        ],
      })),
      translations_attributes: [
        {
          att: "value",
          id: translations ? translations[0]?.id : null,
          description: data.name,
          locale: loggedUser.primary_lang,
        },
      ],
    };

    if (action === "create") {
      axios
        .post(create[ENTITY](RESTAURANT_ID), { accompaniment })
        .then(() =>
          modalAccompanimentSuccessful("creado", data.name, t, () =>
            history.goBack()
          )
        )
        .then(() => e.target.reset())
        .catch(() => modalAccompanimentError("crear", data.name, t))
        .finally(() => showLoadingOverlay(false));
    } else if (action === "edit") {
      axios
        .put(update[ENTITY](RESTAURANT_ID, id), { accompaniment })
        .then(() => {
          data.id = id;
          updateAccompaniment(data);
        })
        .then(() =>
          modalAccompanimentSuccessful("actualizado", data.name, t, () =>
            history.goBack()
          )
        )
        .catch(() => modalAccompanimentError("actualizar", data.name, t))
        .finally(() => showLoadingOverlay(false));
    }
  };

  const handleChange = (value, action, setStateFunction) => {
    const inputRef = action.name;

    if (Array.isArray(value)) {
      setValue(
        inputRef,
        value.map(({ value }) => value)
      );
    } else setValue(inputRef, value.value);

    setStateFunction(value);
  };

  useEffect(() => {
    register("limit");
    register("is_visible");
    register("is_required");
    clearEntityItems();

    if (action === "edit") {
      const isVisibleTransformed = is_visible_options.filter(
        ({ value }) => value == is_visible
      );

      const limitTransformed = ACCOMPANIMENT_LIMIT.filter(
        ({ value }) => value == limit
      );

      const isRequiredTransformed = is_obliged_options.filter(
        ({ value }) => value == is_required
      );

      setIsVisibleSelect(isVisibleTransformed);
      setIsRequiredSelect(isRequiredTransformed);
      setLimitSelect(limitTransformed);

      if (items?.length > 0) addEntityItem(items);
    }
  }, []);

  return (
    <section>
      <form
        className="cartear-accompaniment-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <section className="accompaniment-form-container">
          <section className="row">
            <section className="form-group">
              <label className="cartear-form-label">
                {t("forms.accompaniment.title")}
              </label>
              <input
                readOnly={readOnly}
                name="name"
                maxLength={50}
                className="form-control cartear-form-input"
                defaultValue={name}
                ref={register({
                  required: true,
                  message: t("forms.inputRequired"),
                })}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label className="cartear-form-label" htmlFor="is_visible">
                {t("forms.accompaniment.limit")}
              </label>
              <Select
                isDisabled={readOnly}
                name="limit"
                value={limitSelect}
                placeholder={t("commons.placeholders.select")}
                options={ACCOMPANIMENT_LIMIT}
                onChange={(value, action) => {
                  handleChange(value, action, setLimitSelect);
                }}
                styles={cartearSelectStyles}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label className="cartear-form-label" htmlFor="is_visible">
                {t("forms.accompaniment.isVisible")}
              </label>
              <Select
                isDisabled={readOnly}
                name="is_visible"
                value={isVisibleSelect}
                placeholder={t("commons.placeholders.select")}
                options={is_visible_options}
                onChange={(value, action) => {
                  handleChange(value, action, setIsVisibleSelect);
                }}
                styles={cartearSelectStyles}
              />
            </section>
          </section>
          <section className="row">
            <section className="form-group">
              <label className="cartear-form-label" htmlFor="is_required">
                {t("forms.accompaniment.isRequired")}
              </label>
              <Select
                isDisabled={readOnly}
                name="is_required"
                value={isRequiredSelect}
                placeholder={t("commons.placeholders.select")}
                options={is_obliged_options}
                onChange={(value, action) => {
                  handleChange(value, action, setIsRequiredSelect);
                }}
                styles={cartearSelectStyles}
              />
            </section>
          </section>
        </section>
        <section className="row">
          <ItemsList entity={"accompaniment"} readOnly={readOnly} />
        </section>
        <section className="row">
          {!readOnly && (
            <section className="form-submit-btn">
              <input
                type="submit"
                className="btn gradient-button"
                value={t("commons.buttons.save")}
              />
            </section>
          )}
        </section>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
    entityItems: state.entityItems,
  };
};

export default connect(mapStateToProps, actions)(AccompanimentForm);
