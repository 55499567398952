import React from "react";
import dayjs from "dayjs";

import DatatableActions from "../components/DatatableActions";
import ButtonToggle from "../components/ButtonToggle";
import ModelsIcons from "../components/ModelsIcons";
import PaymentStatus from "../components/PaymentStatus";
import PaymentButton from "../components/PaymentButton";

import unavailableImage from "../images/unavailable-image.jpg";
import { roles } from "./roles.json";

const columns = {
  restaurants: [
    {
      name: "image",
      selector: "image",
      center: true,
      maxWidth: "200px",
      cell: (row) => (
        <div className="datatable-image">
          <img src={row.image ? row.image : unavailableImage} />
        </div>
      ),
    },
    {
      name: "name",
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: "description",
      selector: "description",
      sortable: true,
      center: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],

  products: [
    {
      name: "image",
      selector: "image",
      center: true,
      maxWidth: "200px",
      cell: (row) => (
        <div className="datatable-image">
          <img src={row.image ? row.image : unavailableImage} />
        </div>
      ),
    },
    {
      name: "name",
      selector: "name",
      sortable: true,
      center: true,
      selector: "title",
    },
    {
      name: "isRecommended",
      selector: "is_recommended",
      center: true,
      maxWidth: "200px",
      ignoreRowClick: true,
      cell: (row) => (
        <ButtonToggle data={row} entity={"products"} att={"is_recommended"} />
      ),
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  branches: [
    {
      name: "image",
      selector: "image",
      center: true,
      maxWidth: "200px",
      cell: (row) => (
        <div className="datatable-image">
          <img src={row.image ? row.image : unavailableImage} />
        </div>
      ),
    },
    {
      name: "branch",
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: "isVisible",
      selector: "is_visible",
      center: true,
      maxWidth: "200px",
      ignoreRowClick: true,
      cell: (row) => (
        <ButtonToggle data={row} entity={"branches"} att={"is_visible"} />
      ),
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  allergens: [
    {
      name: "image",
      selector: "image",
      center: true,
      maxWidth: "200px",
      cell: (row) => (
        <div className="datatable-image">
          <img src={row.image ? row.image : unavailableImage} />
        </div>
      ),
    },
    {
      name: "name",
      selector: "name",
      sortable: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  tags: [
    {
      name: "image",
      selector: "image",
      center: true,
      maxWidth: "200px",
      cell: (row) => (
        <div className="datatable-image">
          <img src={row.image ? row.image : unavailableImage} />
        </div>
      ),
    },
    {
      name: "name",
      selector: "name",
      sortable: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  payment_methods: [
    {
      name: "id",
      selector: "id",
      center: true,
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "method",
      selector: (row) =>
        !!row.translations && row.translations.length > 0
          ? row.translations.filter((trans) => trans.locale === "es")[0]
              ?.description
          : "",
      sortable: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  delivery_methods: [
    {
      name: "id",
      selector: "id",
      center: true,
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "method",
      selector: (row) =>
        !!row.translations && row.translations.length > 0
          ? row.translations.filter((trans) => trans.locale === "es")[0]
              ?.description
          : "",
      sortable: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  users: [
    {
      name: "image",
      selector: "image",
      center: true,
      maxWidth: "200px",
      cell: (row) => (
        <div className="datatable-image">
          <img src={row.avatar ? row.avatar : unavailableImage} />
        </div>
      ),
    },
    {
      name: "name",
      selector: (row) =>
        `${!!row?.first_name ? row.first_name : ""} ${
          !!row?.last_name ? row.last_name : ""
        }`,
      center: true,
      sortable: true,
    },

    {
      name: "email",
      selector: (row) => row?.email || "",
      center: true,
    },
    {
      name: "restaurant",
      selector: (row) => row?.restaurant_name || "",
      center: true,
    },
    {
      name: "role",
      selector: (row) => roles[row?.role] || "",
      center: true,
    },
    {
      name: "sentEmail",
      selector: (row) =>
        row?.sent_confirmation || row?.send_invitation ? (
          <span className="text-green">SI</span>
        ) : (
          <span className="text-red">NO</span>
        ),
      center: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  currencies: [
    {
      name: "name",
      selector: (row) => row?.name || "",
      sortable: true,
    },

    {
      name: "isoCode",
      selector: (row) => row?.code || "",
      center: true,
    },
    {
      name: "symbol",
      selector: (row) => row?.symbol || "",
      center: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  countries: [
    {
      name: "name",
      selector: "name",
      center: true,
      sortable: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  billing: [
    {
      name: "branch",
      selector: (row) => (row.branch ? branch : ""),
      sortable: true,
    },

    {
      name: "plan",
      selector: (row) => row?.plan || "",
      center: true,
    },
    {
      name: "country",
      selector: (row) => row?.country || "",
      center: true,
    },
    {
      name: "paidStatus",
      selector: "paid",
      center: true,
      maxWidth: "200px",
      ignoreRowClick: true,
      cell: (row) => (
        <ButtonToggle data={row} entity={"billing"} att={"is_visible"} />
      ),
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  menus: [
    {
      name: "name",
      selector: "name",
      center: true,
      sortable: true,
    },
    {
      name: "branch",
      selector: (row) => (row?.branch_name ? row.branch_name : "-"),
      center: true,
    },
    {
      name: "categoriesCount",
      selector: "menu_categories_count",
      center: true,
    },
    {
      name: "state",
      selector: (row) => (
        <ButtonToggle data={row} entity={"menus"} att={"active"} />
      ),
      center: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  categories: [
    {
      name: "image",
      selector: "image",
      center: true,
      maxWidth: "200px",
      cell: (row) => (
        <div className="datatable-image">
          <img src={row.image ? row.image : unavailableImage} />
        </div>
      ),
    },
    {
      name: "category",
      selector: "name",
      center: true,
      sortable: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  toppings: [
    {
      name: "toppings",
      selector: "name",
      center: true,
      sortable: true,
    },
    {
      name: "isVisible",
      selector: "is_visible",
      center: true,
      maxWidth: "200px",
      ignoreRowClick: true,
      cell: (row) => (
        <ButtonToggle data={row} entity={"toppings"} att={"is_visible"} />
      ),
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  accompaniments: [
    {
      name: "accompaniments",
      selector: "name",
      center: true,
      sortable: true,
    },
    {
      name: "isVisible",
      selector: "is_visible",
      center: true,
      maxWidth: "200px",
      ignoreRowClick: true,
      cell: (row) => (
        <ButtonToggle data={row} entity={"accompaniments"} att={"is_visible"} />
      ),
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  variants: [
    {
      name: "variants",
      selector: "name",
      center: true,
      sortable: true,
    },
    {
      name: "isVisible",
      selector: "is_visible",
      center: true,
      maxWidth: "200px",
      ignoreRowClick: true,
      cell: (row) => (
        <ButtonToggle data={row} entity={"variants"} att={"is_visible"} />
      ),
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  models: [
    {
      name: "dishName",
      selector: (row) => (!!row.title ? row.title : ""),
      center: true,
      sortable: true,
    },
    {
      name: "model",
      selector: "is_visible",
      center: true,
      maxWidth: "300px",
      ignoreRowClick: true,
      cell: (row) => <ModelsIcons data={row} />,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  payment_transactions: [
    {
      name: "Período",
      selector: (row) =>
        `${dayjs(row.start_period).format("DD/MM/YYYY")} - ${dayjs(
          row.end_period,
        ).format("DD/MM/YYYY")}`,
      center: true,
      sortable: true,
    },
    {
      name: "Monto",
      selector: (row) =>
        !!row.first_total
          ? `${row.plan_country.currency.symbol} ${parseFloat(
              row.first_total,
            ).toFixed(2)}`
          : "-",
      center: true,
      sortable: true,
    },
    {
      name: "Estado",
      center: true,
      maxWidth: "200px",
      ignoreRowClick: true,
      cell: (row) => <PaymentStatus data={row} />,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <PaymentButton data={row} />,
    },
  ],
  plans: [
    {
      name: "image",
      selector: "image",
      center: true,
      maxWidth: "200px",
      cell: (row) => (
        <div className="datatable-image">
          <img src={row.image ? row.image : unavailableImage} />
        </div>
      ),
    },
    {
      name: "name",
      selector: (row) => row?.name || "",
      center: true,
      sortable: true,
    },
    {
      name: "recommendedDishes",
      selector: (row) => (row?.recommended_dishes ? "Si" : "No"),
      center: true,
      sortable: true,
    },
    {
      name: "maximumMenus",
      selector: (row) => row?.maximum_menus,
      center: true,
      sortable: true,
    },
    {
      name: "cartearWaiters",
      selector: (row) => (row?.cartear_waiters ? "Si" : "No"),
      center: true,
      sortable: true,
    },
    {
      name: "notifications",
      selector: (row) => (row?.notifications ? "Si" : "No"),
      center: true,
      sortable: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  plan_promotions: [
    {
      name: "period",
      selector: (row) => `${row?.period} Meses`,
      center: true,
      sortable: true,
    },
    {
      name: "discount",
      selector: (row) => `${row?.discount}%`,
      center: true,
      sortable: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  mobile_versions: [
    {
      name: "image",
      selector: "image",
      center: true,
      maxWidth: "200px",
      cell: (row) => (
        <div className="datatable-image">
          <img src={row.image ? row.image : unavailableImage} />
        </div>
      ),
    },
    {
      name: "version",
      selector: (row) => row?.current || "",
      center: true,
      sortable: true,
    },
    {
      name: "minimumVersion",
      selector: (row) => row?.min_supported || "",
      center: true,
      sortable: true,
    },
    {
      name: "so",
      selector: (row) => row?.so || "",
      center: true,
      sortable: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  suggestions: [
    {
      name: "uid",
      selector: "uuid",
      center: true,
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row?.email || "mail@example.com",
      center: true,
      sortable: true,
    },
    {
      name: "comment",
      selector: (row) => row?.body || "-",
      center: true,
      sortable: true,
      maxWidth: "500px",
    },
    {
      name: "date",
      selector: (row) => row?.date || "10/07/2021",
      center: true,
      sortable: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
  notifications: [
    {
      name: "date",
      selector: "date",
      center: true,
      sortable: true,
    },
    {
      name: "topic",
      selector: "topic.name",
      center: true,
      sortable: true,
    },
    {
      name: "hour",
      selector: "time",
      center: true,
      sortable: true,
    },
    {
      name: "title",
      selector: "title",
      center: true,
      sortable: true,
      maxWidth: "500px",
    },
    {
      name: "message",
      selector: "body",
      center: true,
      sortable: true,
    },
    {
      name: "actions",
      selector: "actions",
      center: true,
      maxWidth: "200px",
      cell: (row) => <DatatableActions entity={row} />,
    },
  ],
};

export default columns;
